import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "setting-up-firstparty",
      "style": {
        "position": "relative"
      }
    }}>{`Setting Up Firstparty`}<a parentName="h2" {...{
        "href": "#setting-up-firstparty",
        "aria-label": "setting up firstparty permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Before we begin, you'll need a few things handy:`}</p>
    <ol>
      <li parentName="ol">{`Access to the DNS settings for the domain you specified when signing up`}</li>
      <li parentName="ol">{`Access to your website to install the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`firstparty.js`}</code>{` Javascript library`}</li>
    </ol>
    <h3 {...{
      "id": "point-your-domain-to-firstparty",
      "style": {
        "position": "relative"
      }
    }}>{`Point Your Domain to Firstparty`}<a parentName="h3" {...{
        "href": "#point-your-domain-to-firstparty",
        "aria-label": "point your domain to firstparty permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Firstparty is designed to live at a domain you own, which matches the root domain of the website you'll be collecting
data from. In order to do this, you'll need to setup a CNAME from your DNS provider to the address provided to you by
your account manager.`}</p>
    <ol>
      <li parentName="ol">{`Log into your DNS provider's management system`}</li>
      <li parentName="ol">{`Select the domain of the website you want to collect data from`}</li>
      <li parentName="ol">{`Create a new CNAME record with the subdomain `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`fp`}</code>{` pointing to the full domain provided to you by your account manager`}</li>
      <li parentName="ol">{`Save your changes`}</li>
    </ol>
    <p>{`After this is complete, you can configure Firstparty`}</p>
    <h3 {...{
      "id": "create-your-firstparty-account",
      "style": {
        "position": "relative"
      }
    }}>{`Create Your Firstparty Account`}<a parentName="h3" {...{
        "href": "#create-your-firstparty-account",
        "aria-label": "create your firstparty account permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <ol>
      <li parentName="ol">{`Navigate to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`https://fp.{yourdomain.com}/authentication/install`}</code></li>
      <li parentName="ol">{`Enter your full name, email address, and a secure pasword`}</li>
      <li parentName="ol">{`Click "Finish Setup"`}</li>
      <li parentName="ol">{`Login to Firstparty!`}</li>
    </ol>
    <h3 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}>{`What's Next`}<a parentName="h3" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`You've now finished setting up your account, so it's time to `}<a parentName="p" {...{
        "href": "/docs/setup/install-web/"
      }}>{`install Firstparty on your website`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      